export default class {
    init() {
        let removeButton = document.querySelectorAll('.cart-product__remove');

        // if( removeButton.length ) {
        //     removeButton.forEach(function(item) {
        //         let data = {
        //             item_name: item.getAttribute('data-product-name'),
        //             item_id: item.getAttribute('data-product-id'),
        //             item_category: item.getAttribute('data-product-category'),
        //             price: item.getAttribute('data-product-price'),
        //             quantity: item.getAttribute('data-product-quantity'),
        //         }

        //         item.addEventListener('click', function() {
        //             console.log('remove_from_cart_action');
        //             window[ gtm4wp_datalayer_name ].push({
        //                 'event': 'remove_from_cart',
        //                 'ecommerce': {
        //                     'currency': gtm4wp_currency,
        //                     'items': data
        //                 }
        //             });
        //         });
        //     });
        // }
    }
}