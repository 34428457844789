export default class {
  triggers: any
  activeClass: string

  constructor() {
    this.triggers = document.querySelectorAll('[data-modal-btn]')
    if (this.triggers.length) {
      this.activeClass = 'modal--active'
      this.triggerHandler()
    }
  }

  triggerHandler() {
    this.triggers.forEach(trigger => {
      trigger.addEventListener('click', e => {
        e.preventDefault()
        const id = trigger.getAttribute('href').replace('#', '')
        const modal = document.getElementById(`modal-${id}`)

        this.toggleModal(modal)
        this.closeModalTriggers(modal)
      })
    })
  }

  toggleModal(modal: HTMLElement) {
    modal.classList.toggle(this.activeClass)
  }

  closeModalTriggers(modal: HTMLElement) {
    modal.querySelector('.modal__close').addEventListener('click', () => modal.classList.remove(this.activeClass))
    modal.querySelector('.modal__overlay').addEventListener('click', () => modal.classList.remove(this.activeClass))
  }
}
