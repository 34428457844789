interface ISettings {
  container: string
  offset: number
  separator: string
  total: number
}

type IOnEvent = 'click'

export default class {
  private container: HTMLElement
  private numbers: Array<number | string>
  private settings: ISettings
  private numbersContainer: HTMLElement
  public current: number
  public next: number | boolean
  public prev: number | boolean
  public prevButton: HTMLElement
  public nextButton: HTMLElement
  public clickCallback: Function

  constructor(settings?: Partial<ISettings>) {
    this.settings = {
      container: '#pagination',
      offset: 2,
      separator: '...',
      total: 10,
      ...settings
    }
    this.container = document.querySelector(this.settings.container)
  }

  run(current: number) {
    if (this.container) {
      this.numbersContainer = this.container.querySelector('.pagination__numbers')
      this.prevButton = this.container.querySelector('.pagination-prev')
      this.nextButton = this.container.querySelector('.pagination-next')
      this.current = current
      this.setNumbers(this.current)
      this.render()
      return this
    }
  }

  on(event: IOnEvent, callback: Function) {
    switch (event) {
      case 'click':
        this.clickCallback = callback
        break

      default:
        break
    }

    return this
  }

  private setNumbers(current: number) {
    const { total, offset, separator } = this.settings

    const left = current - offset
    const right = current + offset + 1
    const range = []
    const rangeWithDots = []
    let placeOfSeparator

    for (let i = 1; i <= total; i++) {
      if (i == 1 || i == total || (i >= left && i < right)) {
        range.push(i)
      }
    }

    for (let i of range) {
      if (placeOfSeparator) {
        if (i - placeOfSeparator === offset) {
          rangeWithDots.push(placeOfSeparator + 1)
        } else if (i - placeOfSeparator !== 1) {
          rangeWithDots.push(separator)
        }
      }
      rangeWithDots.push(i)
      placeOfSeparator = i
    }

    this.setNext()
    this.setPrev()

    this.numbers = rangeWithDots
    return this
  }

  private render() {
    if (this.numbers.length) {
      this.numbersContainer.innerHTML = ''

      this.numbers.forEach(item => {
        let elem: HTMLElement

        if (item == this.settings.separator || item == this.current) {
          elem = document.createElement('span')
        } else {
          elem = document.createElement('a')
          elem.classList.add('page-number')
        }

        if (item == this.current) {
          elem.classList.add('current')
          elem.classList.add('page-number')
        }

        elem.innerText = `${item}`

        elem.addEventListener('click', e => {
          const number = parseInt(elem.innerText)
          this.clickCallback(e, {
            current: parseInt(elem.innerText)
          })
          // this.run(number)
        })

        this.numbersContainer.appendChild(elem)
      })
    }
  }

  private setPrev() {
    const prev = this.current - 1
    if (prev >= 0) {
      this.prev = prev
      this.toggleButton(this.prevButton, prev)
    } else {
      this.toggleButton(this.prevButton, 0)
    }
  }

  private setNext() {
    const next = this.current + 1
    if (next <= this.settings.total) {
      this.next = next
      this.toggleButton(this.nextButton, next)
    } else {
      this.toggleButton(this.nextButton, 0)
    }
  }

  private toggleButton(elem: HTMLElement, number: number) {
    elem.setAttribute('data-paged', `${number}`)

    number == 0 ? elem.setAttribute('disabled', 'true') : elem.setAttribute('disabled', 'false')

    elem.addEventListener('click', e => {
      e.preventDefault()
      // this.run(number)
    })
  }

  public setTotal(total: number) {
    this.settings.total = total
    return this
  }
}
