export default () => {
  const buttons = document.querySelectorAll(
    '.wp-block-button__link, button:not(.button--icon), .button'
  )

  if (buttons.length) {
    buttons.forEach(button => {
      if (!button.querySelector('.button__inner')) {
        const span = document.createElement('span')
        span.className = 'button__inner'
        button.append(span)
      }
    })
  }
}
