class ProductFilter {
    constructor() {
      this.filterContainer = document.querySelector('#product_filters');
      this.filters = document.querySelectorAll('.product_filter');
      this.container = document.querySelector('.filtered_products');
      this.products = document.querySelectorAll('.filtered_products .product');
      this.productsTags = [];
      this.activeFilters = [];
      this.init();
      this.initProductsTags();
      this.initToggleFIlters();
      this.actionButtons();
    }

    init() {
        let _this = this;
        if( this.filterContainer && this.filters ) {
            this.filters.forEach(filter => {
                filter.addEventListener('click', function() {
                    _this.updateFilters();
                    _this.filterProducts();
                })
            })
        }
    }

    initProductsTags() {
        if(this.products) {
            this.products.forEach(product => {
                this.productsTags.push(product.getAttribute('data-categories').split(','));
            })
        }
    }
    initToggleFIlters() {
        let toggleFilters = document.querySelector('.toggleFilters');

        if( toggleFilters ) {
            let wrapper = document.querySelector('.productFilter__filtersOuter');
            let contentHeight = document.querySelector('.productFilter__filtersWrapper').offsetHeight;

            toggleFilters.addEventListener('click', function(e){
                e.preventDefault();
                if(this.classList.contains('active')) {
                    wrapper.style.height = '140px';
                    this.classList.remove('active');
                    this.querySelector('.content').innerHTML = "Rozwiń filtry";
                } else {
                    wrapper.style.height = contentHeight + 'px';
                    this.classList.add('active');
                    this.querySelector('.content').innerHTML = "Zwiń filtry";
                }
            });
        }
    }

    actionButtons() {
        let all = document.querySelector('.productsFilter .selectAll');
        let none = document.querySelector('.productsFilter .deselectAll');
        let filters = document.querySelectorAll('.product_filter');
        let _this = this;

        if (all && none && filters.length) {
            all.addEventListener('click', function(e) {
                e.preventDefault();
                filters.forEach(function(item, index) {
                    item.checked = true;
                });
                _this.updateFilters();
                _this.filterProducts();
            });

            none.addEventListener('click', function(e) {
                e.preventDefault();
                filters.forEach(function(item, index) {
                    item.checked = false;
                });
                _this.updateFilters();
                _this.filterProducts();
            });
        }
    }

    updateFilters() {
        this.activeFilters = [];
        var activeInputs = this.filterContainer.querySelectorAll('input:checked');
        console.log('update filters')

        activeInputs.forEach(input => {
            this.activeFilters.push(input.value);
        });
    }
    filterProducts() {
        let _this = this;
        if( this.activeFilters.length ) {
            this.products.forEach( (product, index) => {
                let activeState = false;
                let tags = this.productsTags[index];

                this.activeFilters.forEach(filter => {
                    if( tags.includes(filter) ) {
                        activeState = true;
                    }
                })

                if( !activeState ) {
                    product.classList.add('inactive');
                } else {
                    product.classList.remove('inactive');
                }

            })
        } else {
            this.products.forEach(product => {
                product.classList.remove('inactive');
            });
        }

    }
}

export default ProductFilter