class Experts {
  constructor() {
    this.activeClass = 'active';
  }

  init() {
        let experts = document.querySelector('.experts');

        if( experts ) {
            var slider = tns({
              container: experts,
              items: 1,
              slideBy: 1,
              autoplay: false,
              nav: false,
              speed: 300,
              loop: false,
              rewind: false,
              controls: true,
              gutter: 0,
              touch: true,
              responsive: {
                1023: {
                  disable: true,
                }
              }
            });
        }
  }
}

export default Experts