import ScrollMagic from 'scrollmagic'

interface ISettings {
  direction: 1 | -1
  progress: number
  max: number
}

export default class {
  container: HTMLElement
  controller: any

  constructor() {
    this.container = document.querySelector('.section-with-sliding-photos')
    this.controller = new ScrollMagic.Controller()
  }

  run() {
    if (this.container) {
      const images = this.container.querySelectorAll(
        '.section-with-sliding-photos__images .image-container'
      )

      const first = <HTMLElement>images[0]
      const second = <HTMLElement>images[1]
      const third = <HTMLElement>images[2]

      new ScrollMagic.Scene({ triggerElement: images, duration: '100%' })
        .addTo(this.controller)
        .on('progress', e => {
          const progress = Math.round(e.progress * 100)
          this.slide(first, {
            progress: progress / 3,
            direction: -1
          })
          this.slide(second, {
            progress: progress / 3.5,
            max: 80
          })
          this.slide(third, {
            progress: progress / 1,
            direction: -1
          })
        })
    }
  }

  slide(triggerElement: HTMLElement, settings?: Partial<ISettings>) {
    const setts = {
      direction: 1,
      progress: 0,
      max: 100,
      ...settings
    }

    const { direction, progress, max } = setts
    if (progress <= max) triggerElement.style.transform = `translateY(${progress * direction}%)`
  }
}
