class Readmore {
  constructor() {
    this.activeClass = 'active';
  }

  init() {
        let contentBlocks = document.querySelectorAll('.readmore-block');

        if (contentBlocks.length) {
            contentBlocks.forEach(elem => {
                let shortContent = elem.querySelector('.readmore-content');

                if( shortContent ) {
                    let longContent = elem.querySelector('.readmore-fullContent');
                    let button = document.createElement('button');
                        button.classList.add('readmore-button');
                        button.innerHTML = '...';
                        shortContent.querySelector('p:last-of-type').append(button);


                        button.addEventListener('click', function() {
                            elem.style.height = elem.offsetHeight + 'px';
                            shortContent.style.display = 'none';
                            longContent.style.display = 'block';
                            elem.style.height = elem.querySelector('.readmore-inner').offsetHeight;


                            setTimeout(function() {
                                elem.style.heigt = '';
                            }, 700)
                        });
                }


            })
        }
  }
}

export default Readmore