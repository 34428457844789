/**
 * @link https://www.npmjs.com/package/smooth-scroll
 */

import SmoothScroll from 'smooth-scroll'

new SmoothScroll('a[href*="#"]', {
  ignore: '[data-vc-container], [data-toggle]',
  header: '#masthead',
  speed: 800
})

// window.addEventListener('load', () => {
//   const anchor = window.location.hash
//   const elem = anchor && document.querySelector(anchor)
//   if (elem) {
//     setTimeout(() => window.scrollTo(0, 0), 1)
//     setTimeout(
//       () =>
//         smoothScroll.animateScroll(elem, null, {
//           offset: 100
//         }),
//       1000
//     )
//   }
// })
