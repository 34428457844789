import CoreAnimation from '../animations/CoreAnimations'

export default class {
  constructor() {
    this.activeClass = 'accordion__item--active'
  }

  init() {
    this.accordions = document.querySelectorAll('.accordion')

    if (this.accordions.length) {
      this.accordions.forEach(accordion => {
        this.singles = accordion.querySelectorAll('.accordion__item')

        this.singles.forEach(single => {
          const btn = single.querySelector('[data-toggle="collapse"]')
          btn.addEventListener('click', () => {
            this.toggle(single)
            const id = btn.closest('.accordion__item').getAttribute('id')
            if (id) history.pushState(null, null, `#${id}`)
          })
        })

        this.getHandler(accordion)
      })
    }
  }

  toggle(single) {
    const content = single.querySelector('.accordion__content')
    single.classList.toggle(this.activeClass)
    CoreAnimation.slideToggle(content)
  }

  show(single) {
    const content = single.querySelector('.accordion__content')
    if (!single.classList.contains(this.activeClass)) {
      single.classList.add(this.activeClass)
      CoreAnimation.slideDown(content)
    }
  }

  hide(single) {
    const content = single.querySelector('.accordion__content')
    single.classList.remove(this.activeClass)
    CoreAnimation.slideUp(content)
  }

  getHandler(accordion) {
    const { hash } = location

    if (hash) {
      const single = accordion.querySelector(hash)
      single && this.toggle(single)
    }
  }
}
