class MobileSlider {
  constructor() {
    this.activeClass = 'active';
  }

  init() {
        let containers = document.querySelectorAll('.mobile-slider');

        if( containers.length ) {
            containers.forEach(function(item) {
                let prev = item.parentNode.nextElementSibling.querySelector('.prev');
                let next = item.parentNode.nextElementSibling.querySelector('.next');


                var slider = tns({
                    container: item,
                    items: 1,
                    slideBy: 1,
                    autoplay: false,
                    nav: false,
                    speed: 300,
                    loop: false,
                    rewind: false,
                    controls: false,
                    gutter: 0,
                    touch: true,
                    responsive: {
                        1023: {
                        disable: true,
                        }
                    }
                });

                prev.addEventListener('click', function() {
                    slider.goTo('prev');
                });
                next.addEventListener('click', function() {
                    slider.goTo('next');
                });
            })
        }
  }
}

export default MobileSlider