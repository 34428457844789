class Newsletter {
  constructor() {
    this.activeClass = 'active';
  }

  init() {
        let cover = document.querySelector('.page-cover');
        let newsletter = document.querySelector('.newsletter-inner');
        let accepted = window.localStorage.getItem('newsletter-accept');
        let close = document.querySelector('.newsletter-close');

        if (!accepted && newsletter ) {
            setTimeout(function() {
                cover.classList.add('active');
                newsletter.classList.add('active');
            }, 3000);


            close.addEventListener('click', function() {
                cover.classList.remove('active');
                newsletter.classList.remove('active');
                window.localStorage.setItem('newsletter-accept', 'true');
            });


            let wpcf7Elm = newsletter.querySelector( '.wpcf7' );

            wpcf7Elm.addEventListener('wpcf7mailsent', function() {
                console.log('eeee');
              newsletter.classList.add('sent');
              window.localStorage.setItem('newsletter-accept', 'true');
            } );



        }
  }
}

export default Newsletter