import FloatLabels from './forms/float-labels'
import LazyLoad from 'vanilla-lazyload'
import MainNav from './navigation'
import MobileMenu from './MobileMenu'
import Modal from './modules/Modal'
import SiteHeader from './siteHeader'
import SlidingPhotos from './modules/SlidingPhotos'
import breadcrumbs from './modules/breadcrumbs'
import buttons from './buttons'
import inputQuantity from './modules/inputQuantity'
import { isMobile } from './helper'
import Blog from './Blog'
import LatestPosts from './modules/LatestPosts'
import GutenbergAnimations from './animations/GutenbergAnimations'
import MicroModal from 'micromodal'
import closeCartModal from './woocommerce/closeCartModal'
import ProductFilter from './productFilter'
import Accordion from './modules/Accordion'
import Readmore from './modules/readmore'
import Experts from './modules/experts'
import MobileSlider from './modules/mobileSlider'
import PartnersSlider from './modules/PartnersSlider'
import Newsletter from './modules/newsletter'
import CustomGaEvent from './modules/customGaEvent';
// import AOS from 'aos'
// import 'aos/dist/aos.css'

window.addEventListener('DOMContentLoaded', () => {
  new SiteHeader({
    setBodyPaddingTop: false
  })
  new FloatLabels()
  buttons()
  new LazyLoad()
  breadcrumbs()
  new SlidingPhotos().run()
  new Blog().run()
  new LatestPosts().run()

  inputQuantity()
  new Modal()
  MicroModal.init()
  new closeCartModal()
  new Accordion().init()
  new Readmore().init()
  new Experts().init()
  new MobileSlider().init()
  new PartnersSlider().init()
  new Newsletter().init()
  new CustomGaEvent().init()

  // AOS.init({
  //   disable: 'mobile',
  //   once: true,
  //   duration: 1000,
  //   mirror: true
  // })

  new GutenbergAnimations()
    .animation('.wp-block-video', 'fade-up')
    .animation('h2.has-text-align-center', 'fade-down')
    .animation('.shift-image-section', 'fade-up')
    .animation('.latest-posts', 'fade-up')
    .animation('.woocommerce-products-header', 'fade-up')
    .animation('body.tax-product_cat .container-grid .type-product', 'fade-right')
    .animation('.wp-block-image.oval.oval--right', 'fade-left')
    .animation('.single-post .entry-content > *', 'fade-up')
    .columns()

  const Menu = new MainNav()
  if (isMobile()) {
    Menu.closeMenu()
    Menu.addSticky()
    new MobileMenu()
  }

  const ProductFilterList = new ProductFilter()

  window.onresize = () => {
    if (isMobile()) {
      Menu.closeMenu()
      Menu.addSticky()
    }
  }
})
