import { isPhone } from './../helper'

export default () => {
  const container = document.querySelector('.breadcrumbs')

  if (container && isPhone()) {
    const last = container.querySelector('.breadcrumb_last')
    const prevLastLink = last.parentElement.querySelector('a')
    const yoast = container.querySelector('.yoast-breadcrumbs')

    yoast.innerHTML = ''
    yoast.appendChild(prevLastLink)
  }
}
