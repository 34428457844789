import $ from 'jquery'

export default () => {
  document.body.addEventListener('click', e => {
    const { target } = e

    const container = target.closest('.input-quantity')
    if (container) {
      const input = container.querySelector('input[type="number"]') as HTMLInputElement
      const button = target.closest('button')

      let value = parseInt(input.value)
      const type = button.getAttribute('data-type')
      const min = input.getAttribute('min') && parseInt(input.getAttribute('min'))
      const max = input.getAttribute('max') && parseInt(input.getAttribute('max'))
      const step = input.getAttribute('step') && parseInt(input.getAttribute('step'))

      if (type == 'less') {
        const val = value - step
        if (min) {
          if (val >= min) input.value = `${val}`
        } else input.value = `${val}`
      }

      if (type == 'more') {
        const val = value + step
        if (max) {
          if (val <= max) input.value = `${value + step}`
        } else input.value = `${value + step}`
      }

      $('.woocommerce-cart-form .cart_item input').change()
      $(container)
        .closest('form')
        .find('[name="update_cart"]')
        .click()
    }
  })
}
