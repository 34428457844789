import { createLoader } from './helper'
import Pagination from './modules/Pagination'
import LazyLoad from 'vanilla-lazyload'
import SmoothScroll from 'smooth-scroll'

export default class {
  links: NodeListOf<HTMLElement>
  postsContainer: HTMLElement
  pagination: any
  categories: HTMLElement
  categoryId: number | string
  pagedNumber: number | '*'
  SmoothScroll: any
  featuredPostContainer: HTMLElement

  run() {
    const { body } = document

    if (body.classList.contains('blog') || body.classList.contains('category')) {
      this.navigationHandler()

      this.categories = document.getElementById('categories')
      this.pagedNumber = 1
      this.categoryId = '*'
      this.SmoothScroll = new SmoothScroll()
      this.featuredPostContainer = document.getElementById('featured-post')

      let total = jsData.maxPaged
      if (jsData.queriedObject.term_id) {
        this.categoryId = jsData.queriedObject.term_id
        total = jsData.queriedObject.count
      }

      this.fetchPosts()
      this.pagination = new Pagination({ total }).run(1).on('click', (e, options) => {
        this.pagedNumber = options.current
        this.fetchPosts()
      })
    }
  }

  navigationHandler() {
    this.links = document.querySelectorAll('.categories a, .pagination__paged-btn')

    if (this.links.length) {
      this.postsContainer = document.getElementById('posts-container')

      this.links.forEach(link => {
        link.addEventListener('click', e => {
          e.preventDefault()
          const categoryId = link.getAttribute('data-id')
          const pagedNumber = link.getAttribute('data-paged')

          if (pagedNumber) {
            this.pagedNumber = parseInt(pagedNumber)
          }
          if (categoryId) {
            this.links.forEach(link => link.parentElement.classList.remove('is-active'))
            link.parentElement.classList.add('is-active')
            this.categoryId = parseInt(link.getAttribute('data-id'))
            this.pagedNumber = 1
          }

          this.fetchPosts()
        })
      })
    }
  }

  async fetchPosts() {
    createLoader(this.postsContainer)
    const isFeaturedPost = this.featuredPostContainer.querySelector('.type-post') ? true : false

    return await fetch(
      jsData.ajaxUrl +
        `?action=getPostsByIdAjax&catId=${this.categoryId}&paged=${this.pagedNumber}&featuredPost=${isFeaturedPost}`
    )
      .then(res => res.json())
      .then(res => {
        if (res) {
          this.postsContainer.innerHTML = res.response
          this.pagination.setTotal(res.maxPaged).run(this.pagedNumber)
          this.SmoothScroll.animateScroll(this.categories, null, {
            header: '#masthead',
            speed: 1200,
            offset: 40
          })

          if (res.hasOwnProperty('featuredPost')) {
            const { featuredPost } = res
            this.featuredPostContainer.innerHTML = featuredPost
          }
        }
      })
      .catch(error => console.warn(error))
  }
}
