class MobileMenu {
  constructor() {
    this.toggledButton = document.getElementById('toggle')
    this.container = document.getElementById('mobile-menu')
    this.siteHeader = document.getElementById('masthead')
    this.init()
  }

  init() {
    this.toggleMenu()
  }

  toggleMenu() {
    const html = document.querySelector('html')

    if (this.toggledButton && this.container) {
      this.toggledButton.addEventListener('click', () => {
        if (this.toggledButton.classList.contains('hamburger--is-active')) {
          this.toggledButton.classList.remove('hamburger--is-active')
          this.container.classList.remove('mobile-menu--is-active')
          document.body.classList.remove('is-open-menu')
          html.style.overflowY = 'auto'
          return
        }

        this.container.classList.add('mobile-menu--is-active')
        this.toggledButton.classList.add('hamburger--is-active')
        document.body.classList.add('is-open-menu')
        html.style.overflowY = 'hidden'
      })
    }
  }
}

export default MobileMenu
