class PartnersSlider {
   constructor() {
      this.activeClass = 'active';
   }

   init() {
      const partnersSlider = document.querySelector('.partners__slider');

      if (partnersSlider) {
         if (window.innerWidth < 768) {
            const slider = tns({
               items: 3,
               loop: true,
               speed: 350,
               swipeAngle: false,
               autoplayButtonOutput: false,
               container: partnersSlider,
               autoplay: true,
               autoplayTimeout: 2000,
               nav: false,
               rewind: false,
               controls: false,
               mouseDrag: true,
               autoWidth: true,
            });
         } 
         else {
            const slider = tns({
               items: 5,
               loop: false,
               speed: 700,
               swipeAngle: false,
               autoplayButtonOutput: false,
               container: partnersSlider,
               autoplay: true,
               autoplayTimeout: 2000,
               nav: false,
               rewind: false,
               controls: false,
               mouseDrag: true,
               autoWidth: true,
            });
         }
         
      }
   }
}

export default PartnersSlider