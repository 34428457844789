class closeCartModal {
  constructor() {
    this.microModalClose = document.querySelector('.micromodal__close')
    this.microModal = document.querySelector('.micromodal-slide')
    this.init()
  }

  init() {
    this.closeModal()
  }

  closeModal() {
    if (this.microModalClose && this.microModal) {
      this.microModalClose.addEventListener('click', () => {
        this.microModal.classList.remove('is-open')
      })
    }
  }
}

export default closeCartModal
