export default class {
  container: HTMLElement
  images: NodeListOf<Element>
  posts: NodeListOf<Element>

  constructor() {
    this.container = document.querySelector('.latest-posts')
  }

  run() {
    if (this.container) {
      this.images = this.container.querySelectorAll('.images .images__image')
      this.posts = this.container.querySelectorAll('.type-post')

      if (this.images && this.posts) {
        this.posts.forEach(post => {
          post.addEventListener('mouseover', () => {
            const index = [...this.posts].indexOf(post)
            this.images.forEach(image => image.classList.remove('is-active'))
            this.images[index].classList.add('is-active')
          })
          post.addEventListener('mouseleave', () => {
            this.images.forEach(image => image.classList.remove('is-active'))
          })
        })
      }
    }
  }
}
